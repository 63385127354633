import axios, { AxiosResponse } from 'axios';
import * as Cookies from 'js-cookie';

export const getUserId = () => {
  const { REACT_APP_IS_ADMIN } = (window as any).runtimeConfig;
  if (REACT_APP_IS_ADMIN) {
    return 'Admin';
  }
  return '';
};

const getClient = () => {
  const { REACT_APP_CLIENT_CONFIG } = (window as any).runtimeConfig;
  return axios.create({
    ...REACT_APP_CLIENT_CONFIG,
  });
};

const errorHandler = (error: any) => {
  return error?.response;
};

function GET<T = any, R = AxiosResponse<T>>(path: string): Promise<R> {
  return getClient()
    .get(path, {
      headers: {
        Authorization: Cookies.get('spectrum-medical'),
        KioskAuthorization: Cookies.get('spectrum-medical-kiosk'),
      },
    })
    .catch<any>(errorHandler);
}

function POST<T = any, R = AxiosResponse<T>>(
  path: string,
  body?: any,
): Promise<R> {
  return getClient().post(path, body).catch<any>(errorHandler);
}

export const authorize = () => {
  return GET('/Authorize');
};

export const login = (username: string, password: string) => {
  return POST('/Login', { username, password });
};

export const getUser = () => {
  const userId = getUserId();
  return GET(`/GetUser/${userId}`);
};

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  // i18next-http-backend - loads translations from the server
  // https://github.com/i18next/i18next-http-backend
  // here it will read translation files from the public/locales/ folder
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next - see available options - https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnNull: false,
    returnEmptyString: false, // display keys if values are missing. Set this to true when are ready for testing
    detection: {
      caches: ['sessionStorage'], // Tell LanguageDetector plugin to store the default language to sessionStorage
    },
  });

export default i18next;

/**
 * Extract domain from the window.location, and remove sub-domain if any
 */
export default function getDomain() {
  const { hostname } = window.location;
  // Return ip address if it is used as a hostname
  if (ipAddressCheck(hostname)) {
    return hostname;
  }

  const hostnameArray = hostname.split('.');
  if (hostnameArray.length > 2) {
    return hostnameArray.slice(1).join('.');
  }
  // hostname doesn't have a sub-domain, return the hostname
  return hostname;
}

// https://www.w3schools.blog/ip-address-validation-javascript-js
export function ipAddressCheck(address: string) {
  const regEx =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  if (address.match(regEx)) {
    return true;
  }
  return false;
}

import i18n from '../i18n/i18n';

export const CASE_STATUS = 'CASE_STATUS';
export const LIVE_VUE = 'LIVE_VUE';
export const VISION_REPORTS = 'VISION_REPORTS';
export const VISION_ADMIN = 'VISION_ADMIN';
export const E_LEARNING = 'E_LEARNING';

const getApps = () => [
  {
    ID: CASE_STATUS,
    LABEL: i18n.t('CASE STATUS'),
    DESCRIPTION: i18n.t('Real-time department case status'),
  },
  {
    ID: LIVE_VUE,
    LABEL: i18n.t('LIVE VUE'),
    DESCRIPTION: i18n.t('Real-time gauge monitoring'),
  },
  {
    ID: VISION_REPORTS,
    LABEL: i18n.t('VISION REPORTS'),
    DESCRIPTION: 'Standard and custom reports',
  },
  {
    ID: VISION_ADMIN,
    LABEL: i18n.t('VISION ADMIN'),
    DESCRIPTION: i18n.t('Vision configuration and settings'),
  },
  {
    ID: E_LEARNING,
    LABEL: i18n.t('QUANTUM E-LEARNING'),
    DESCRIPTION: i18n.t('Link to Quantum E-Learning'),
  },
];

export default function getAppsMap() {
  /**
   * getApps
   * @returns an a key/value pair of App name and its properties used for translations
   */
  const apps = getApps();
  return Object.assign(
    {},
    ...apps.map((app) => {
      const { ID } = app;
      return {
        [ID]: app,
      };
    }),
  );
}

import React, { Suspense, FC } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.scss';
import { LoginView } from './components/Login/Login';
import { SplashScreen } from './components/Home/Home';

const RenderView: FC<
  any
> = (/* { loading, isLatestVersion, refreshCacheAndReload } */) => {
  return (
    <Router>
      <Route exact path="/auth/:returnUrl?" component={LoginView} />
      <Route exact path="/apps" component={SplashScreen} />
      <Route exact path="/" component={() => <Redirect to="/apps" />} />
    </Router>
  );
};

function App() {
  return (
    <Suspense fallback="loading...">
      <RenderView />
    </Suspense>
  );
}

export default App;

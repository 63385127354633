import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { authorize } from '../../services/client';
import getDomain from '../utilities/getDomain';
import getAppsMap, {
  CASE_STATUS,
  LIVE_VUE,
  VISION_REPORTS,
} from '../../config/apps';
import './Home.scss';

export const SplashScreen = (props: any) => {
  const { REACT_APP_APPS_METADATA } = (window as any).runtimeConfig;
  const history = useHistory();
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [privileges, setPrivileges] = useState<number[]>([]);
  const { t } = useTranslation();
  const apps = useMemo(() => getAppsMap(), []);

  useEffect(() => {
    authorize()
      .then((response: any) => {
        // console.log('authorize response: ', response);
        const { status, data } = response;
        if (status === 200) {
          if (
            data &&
            data.version &&
            data.version === process.env.REACT_APP_VERSION
          ) {
            setAuthorized(true);
            if (data.Privileges) {
              setPrivileges(data.Privileges);
            }
          } else {
            const domain = getDomain();
            Cookies.remove('spectrum-medical', { domain });
            Cookies.remove('spectrum-medical-kiosk', { domain });
            setAuthorized(false);
            history.push('/auth');
          }
        } else {
          const domain = getDomain();
          Cookies.remove('spectrum-medical', { domain });
          Cookies.remove('spectrum-medical-kiosk', { domain });
          setAuthorized(false);
          history.push('/auth');
        }
      })
      .catch((error: any) => {
        console.error('error: ', error);
        if (error?.status === 401) history.push('/auth');
      });
  }, []);

  const livevueAuthorization = (): boolean =>
    privileges.includes(2000) ||
    privileges.includes(2001) ||
    privileges.includes(2002) ||
    privileges.includes(2003);

  const visionReportingAuthorization = (): boolean =>
    privileges.includes(2004) || privileges.includes(2005);

  const shouldHideLiveVue = (metadata: any): boolean =>
    !(
      (metadata.ID === LIVE_VUE || metadata.ID === CASE_STATUS) &&
      !livevueAuthorization()
    );

  const shouldHideVisionReporting = (metadata: any): boolean =>
    !(metadata.ID === VISION_REPORTS && !visionReportingAuthorization());

  const privilegeFilter = (metadata: any) =>
    !metadata.HIDE &&
    shouldHideLiveVue(metadata) &&
    shouldHideVisionReporting(metadata);

  return (
    <div className="page-container">
      {!authorized && (
        <div className="loader">
          <CircularProgress />
          <span>{t('Authorizing')}...</span>
        </div>
      )}
      {authorized && (
        <>
          <div className="cards-container">
            {REACT_APP_APPS_METADATA.filter(privilegeFilter).map(
              (metadata: any) => {
                return (
                  <div className="home-card-container" key={metadata.ID}>
                    <a href={metadata.URL}>
                      <div className="home-card">
                        <div className="visual-area">
                          <img
                            alt={t('CASE STATUS')}
                            src={`/${metadata.LOGO_FILENAME}`}
                          />
                        </div>
                        <div className="title-area">
                          <div className="title">
                            <h1
                              style={{
                                color: metadata.COLOR,
                              }}>
                              {apps[metadata.ID].LABEL}
                            </h1>
                          </div>
                          <div className="subtitle">
                            <h3>{apps[metadata.ID].DESCRIPTION}</h3>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              },
            )}
          </div>
          <div className="footer">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="version">
                {/* eslint-disable-next-line i18next/no-literal-string */}
                {t('Version')}: {process.env.REACT_APP_VERSION}
              </div>
            </div>
            <div className="right-content">
              <div className="branding-logo">
                <img alt="Spectrum Medical" src="spectrum-medical-logo.svg" />
              </div>

              <button
                type="button"
                onClick={() => {
                  const domain = getDomain();
                  Cookies.remove('spectrum-medical', { domain });
                  Cookies.remove('spectrum-medical-kiosk', { domain });
                  history.push('/auth');
                }}>
                {t('Logout')}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
